<template>
	<div class="page-container">
		<div class="header" @click="tool(dian)">
			<page-header :back="false" :pageTitle="pageTitle"></page-header>
		</div>
		<div class="page-body">
			<div class="content">
				<van-address-list
					v-model="chosenPlanId"
					:list="studyPlayList"
					:disabled-list="studyingList"
					disabled-text="当前您正在学习的课程"
					default-tag-text="学习中"
					add-button-text="下一步"
					@add="onNextStep"
					@select="onSelectPlan"
				/>
			</div>
		</div>
		<div class="logout-sp">
			<a href="javascript:void(0)" @click="logout">退出学习</a>
		</div>
		<van-overlay :show="loading" :z-index="99999">
			<div class="wrapper">
				<div class="block">正在切换课程...</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import Vconsole from 'vconsole';
	import PageHeader from "../../components/page-header.vue";
	import {getMyStudyPlanRecord, changeMyStudyPlan, getPayStatus, wxConfig, checkPayStatus, wxAuth} from '@/api/user';
    export default {
        name: "plan-select",
		components: {
			PageHeader
		},
		data() {
			return {
				dian: 0,
				pageTitle: "课程选择",
				loading: false,
				finished: false,
				loginInfor: null,
				chosenPlanId: 0,
				studyPlayList: [],
				studyingList: [],
				checkedPlanObj: null,
				studyingId: 0,
			}
		},
		mounted() {
			const openid = location.search
			if (this.$store.state.cache.screen.wx && !openid.includes('openId=')) {
				let href = window.location.href.replace('login', 'auth')
				wxAuth('', href).then(({url}) => {
					window.location.href = url
				})
			}
			if (this.$store.state.cache.screen.wx) {
				const openid = location.search
				sessionStorage.setItem('openid', openid.split('=')[1])
			}
			const appNo = sessionStorage.getItem('appno');
			if ( appNo !== 'APP03' ) {
				this.$router.push({ path: '/login', query: { appNo: sessionStorage.getItem('appno') }})
			}
			this.loginInfor = JSON.parse(localStorage.getItem('loginInfor_' + appNo));
			if (this.loginInfor == null) {
				this.$router.push({ path: '/login', query: { appNo: sessionStorage.getItem('appno') }})
			}
			if (typeof (this.loginInfor.idcard) == 'undefined' && typeof (this.loginInfor.phone) == 'undefined') {
				this.$router.push({ path: '/login', query: { appNo: sessionStorage.getItem('appno') }})
			}
			this.getMyStudyPlanRecord();
			console.log('this.userid==', this.userid);
			console.log('openid==', sessionStorage.getItem('openid'));
		},
		computed: {
			userid() {
				return this.$store.state.user.userid
			}
		},
		methods: {
			getMyStudyPlanRecord() {
				this.loading = true;
				let param = {};
				if(this.loginInfor.idcard) {
					param = {certNum: this.loginInfor.idcard}
				} else if (this.loginInfor.phone) {
					param = {mobile: this.loginInfor.phone}
				}
				getMyStudyPlanRecord(param)
					.then((res) => {
						for(let i=0; i < res.length; i++) {
							if ( res[i].checkIn === 1 ) {
								this.chosenPlanId = res[i].basePkId;
								this.studyingId = this.chosenPlanId;
								this.studyingList.push({
									id: res[i].basePkId,
									name: res[i].studentName,
									tel: res[i].studyYear,
									address: res[i].planName,
									isPassed: res[i].studentPic === '' ? '0' : '1',
									studentPayStatus: res[i].studentPayStatus,
									studentId: res[i].studentId
								});
							}
							this.studyPlayList.push({
								id: res[i].basePkId,
								name: res[i].studentName,
								tel: res[i].studyYear,
								address: res[i].planName,
								isDefault: res[i].checkIn === 1,
								isPassed: res[i].studentPic === '' ? '0' : '1',
								studentPayStatus: res[i].studentPayStatus,
								studentId: res[i].studentId
							});
						}
						this.loading = false;
					})
					.catch(() => {
						this.loading = false;
					});
			},
			onSelectPlan(item, index) {
				this.checkedPlanObj = item;
				console.log(index)
			},
			onNextStep() {
				if (this.checkedPlanObj != null) {
					//console.log('this.checkedPlanObj.id=', this.checkedPlanObj.id);
					//console.log('this.chosenPlanId=', this.chosenPlanId);
					if (this.checkedPlanObj.id !== this.studyingId) {
						// 切换
						this.loading = true;
						changeMyStudyPlan({
							"basePkId": this.checkedPlanObj.id,
							"studyYear": this.checkedPlanObj.tel
						}).then(() => {
							this.loading = false;
							if (this.checkedPlanObj.studentPayStatus !== '0') {
								this.$router.push({path: "/train"});
							} else {
								this.payOrder();
							}
						})
						.catch(() => {
							this.loading = false;
						});
					} else {
						this.$router.push({path: "/train"});
					}
				} else {
					// 学员没有进行选课
					if (this.studyingList.length === 1) {
						let plan = this.studyingList[0];
						if (plan.studentPayStatus !== '0') {
							this.$router.push({path: "/train"});
						} else {
							this.payOrder();
						}
					} else {
						this.$dialog.alert({message: "目前您没有正在学习的课程"});
					}
				}
			},
			payOrder() {
				const openid = sessionStorage.getItem('openid')
				getPayStatus({
					studentId: this.userid,
					openId: openid
				}).then((data) => {
					if (this.$store.state.cache.screen.wx) {
						this.wxPay(data)
					} else if (this.$store.state.cache.screen.isAndroid) {
						window.open(data.wxOrderInfo.mwebUrl)
					}
				})
			},
			wxPay (data) {
				const _this = this
				wxConfig().then(res => {
					wx.config({
						debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						appId: res.appId, // 必填，公众号的唯一标识
						timestamp: res.timestamp, // 必填，生成签名的时间戳
						nonceStr: res.nonceStr, // 必填，生成签名的随机串
						signature: res.signature,// 必填，签名
						jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
					})
				})
				wx.ready(() => {
					wx.chooseWXPay({
						timestamp: data.wxOrderInfo.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
						nonceStr: data.wxOrderInfo.nonceStr, // 支付签名随机串，不长于 32 位
						package: data.wxOrderInfo.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
						signType: data.wxOrderInfo.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
						paySign: data.wxOrderInfo.paySign, // 支付签名
						success: function () {
							// 支付成功后的回调函数
							_this.checkPayStatus(data.outTradeNo)
						},
						fail: function (error) {
							console.log('支付失败：' + error)
						}
					});
				})
			},
			/**
			 * 检测支付状态
			 */
			checkPayStatus(orderId) {
				checkPayStatus({
					studentId: this.userid,
					outTradeNo: orderId
				}).then(({ payStatus }) => {
					if (typeof payStatus !== "number") {
						payStatus = Number(payStatus)
					}
					if (payStatus === 1) {
						this.$router.push({path: "/train"});
					} else {
						this.checkPayStatus(orderId)
					}
				})
			},
			logout() {
				// 进入学习中心
				this.$router.push({ path: '/study-center'})
			},
			tool(dian) {
				this.dian = dian + 1
				if (this.dian >= 5) {
					new Vconsole();
				}
			}
		}
    }
</script>

<style lang="less" scoped>
	.van-address-item__edit {
		display: none
	}
	.van-button--danger {
		color: #fff;
		background-color: #1175fe;
		border: 1px solid #1175fe;
	}
	.logout-sp {
		position: absolute;
		right: 20px;
		top: 24px;
	}
	.logout-sp a {
		color: #ffffff;
	}
	.imp-check {
		color: #ee0a24;
		font-weight: bold;
	}
	.header {
		position: absolute;
		width: 100%;
		height: 200px;
		padding-bottom: 14px;
		border-bottom-left-radius: 26px 10px;
		border-bottom-right-radius: 26px 10px;
	}

	.page-container {
		min-height: 100vh;
		background: #f5f5f5;
	}

	.page-body {
		margin-top: 0;
		padding: 100px 15px 15px;

		.content {
			position: relative;
			top: -14px;
			background: #fff;
			border-radius: 14px;
			padding: 0;

			.content-wrap {
				.section-top {
					padding: 26px 15px 60px;
					box-shadow: 0 2px 8px 1px #e1e0df;
					border-radius: 14px;

					.title {
						position: relative;
						font-size: 17px;
						text-align: center;

						.txt {
							position: relative;
						}

						.line {
							position: absolute;
							top: 50%;
							display: inline-block;
							width: 30px;
							height: 2px;
							background: #1175fe;
							margin: -1px 0 0;

							&:nth-of-type(1) {
								left: -42px;
							}

							&:nth-of-type(2) {
								right: -42px;
							}
						}
					}

					.para {
						line-height: 1.8;
					}

					.extra {
						margin-top: 40px;

						.extra-title {
							position: relative;
							font-weight: bold;

							&.has-line {
								padding-left: 10px;
							}

							&::before {
								position: absolute;
								content: "";
								width: 4px;
								height: 14px;
								background: linear-gradient(90deg, #1175fe, #1175fe);
								border-radius: 4px;
								left: 0;
								top: 50%;
								margin-top: -6px;
							}
						}

						.extra-content {
							margin-top: 15px;

							.line {
								position: relative;
								line-height: 1.8;
								display: flex;
								align-items: baseline;

								.idx {
									display: inline-block;
									width: 14px;
									height: 14px;
									background: #1175fe;
									border-radius: 14px;
									margin-right: 8px;
									flex: 0 0 14px;
									text-align: center;
									line-height: 14px;
									color: #fff;
									font-size: 12px;
								}
							}
						}
					}
				}

				.section-bottom {
					margin-top: 40px;
				}
			}

			.info {
				display: flex;
				justify-content: space-evenly;
				align-items: center;

				.info-item {
					text-align: center;
					font-size: 15px;

					.top {
						margin-bottom: 8px;
						font-size: 18px;
					}

					.bottom {
						color: #666;
						font-size: 14px;
					}
				}
			}

			.btn-wrap {
				margin-top: 40px;

				.a-btn {
					margin-top: 20px;
					display: flex;
					justify-content: center;

					a {
						text-decoration: underline;

						& + a {
							margin-left: 20px;
						}
					}
				}
			}
		}

		.record {
			padding: 15px;
			background: #ffffff;
			border-radius: 14px;

			.table {
				margin-top: 24px;
				max-height: 300px;
				overflow-y: auto;

				.table-list {
					display: flex;
					align-items: center;

					& + .table-list {
						margin-top: 8px;
					}

					.item {
						font-size: 12px;
						flex: 0 0 70%;
						text-align: center;

						&:first-of-type {
							flex: 0 0 15%;
							text-align: left;
						}

						&:last-of-type {
							flex: 0 0 15%;
							text-align: left;
						}

						&.grey {
							color: #999;
						}

						&.red {
							color: #ff300e;
						}

						&.orange {
							color: #ff8506;
						}
					}

					&.hd {
						.item {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	// .van-popup {
	//   background: transparent;
	// }

	.van-popup .van-popover__arrow {
		background: #fca142;
	}

	.van-popover__content {
		background: #fca142;
		color: #fff;
	}

	.van-circle {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.circle-wrap {
		position: relative;
		height: 200px;

		.circle {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 160px;
			height: 160px;
			margin: -80px 0 0 -80px;
			background-image: url(~@/assets/icon-circle-sm.png);
			background-size: cover;

			.circle-inner {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;

				.title {
					margin: 0 0 4px 0;
					color: #ff8506;
					font-size: 20px;
				}

				span {
					color: #999;
					font-size: 11px;
				}
			}
		}
	}

	.popup-reset {
		width: 290px;
		padding: 20px;

		.content {
			line-height: 1.8;
			margin-bottom: 20px;
		}

		.btn-wrap {
			display: flex;
			justify-content: space-evenly;
			width: 100%;

			.btn {
				width: 40%;
			}
		}
	}

	.popup-content {
		width: 290px;
		// border-radius: 12px;
		overflow: hidden;
		background: #fff;

		& > .title {
			background: linear-gradient(90deg, #fca142, #ff8506);
			padding: 15px;
			font-size: 18px;
			color: #fff;
			text-align: center;
		}

		.content {
			margin: 20px 0 10px;
			padding: 0 10px;
			font-size: 12px;

			.list {
				display: flex;
				align-items: center;
				margin-top: 10px;

				span {
					display: inline-block;
					width: 50%;
				}
			}
		}

		.btn-wrap {
			display: flex;
			padding: 15px;

			.btn {
				width: 40%;
				font-weight: 500;
			}
		}
	}

	.APP02 {
		@colorMain: #2db1ac;
		@colorSecond: #48d8a0;

		.circle-wrap {
			.circle {
				.circle-inner {
					.title {
						color: @colorMain;
					}
				}
			}
		}
	}

	.APP03 {
		@colorMain: #1175fe;
		@colorSecond: #3fadfa;

		.circle-wrap {
			.circle {
				.circle-inner {
					.title {
						color: @colorMain;
					}
				}
			}
		}
	}
</style>
